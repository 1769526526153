import React, { useState } from "react";

const EditorialSearchForm = ({
    buttonTitle,
    inputName,
    placeholder,
    trackingClearValue,
    trackingClickValue,
    url,
}) => {
    const [formEnabled, setFormEnabled] = useState(false);
    const [searchText, setSearchText] = useState("");

    const onSubmit = (e) => {
        if(!formEnabled){
            e.preventDefault();
        }
        if (window.CsnInsightsEventTracker) {
            window.CsnInsightsEventTracker.sendClick(undefined, "editorial-homepage-module", trackingClickValue);
        }
    }

    const onChange = (e) => {
        const value = e.target.value;
        setSearchText(value);

        // Cater for disabling form outside of onSubmit handler which can have stale state
        setFormEnabled(!!value);
    }

    return (
        <form
            action={url}
            className="editorial-search-form"
            method="get"
            onSubmit={onSubmit}
        >
            <div className="editorial-search-form__input-wrapper">
                <input
                    autoComplete="off"
                    name={inputName}
                    onChange={onChange}
                    placeholder={placeholder}
                    spellCheck="false"
                    value={searchText}
                />
                <a
                    className={`editorial-search-form__clear-btn ${searchText ? "" : "hidden"}`}
                    data-webm-clickvalue={trackingClearValue}
                    href="javascript:void(0)"
                    onClick={() => setSearchText("")}
                    role="button"
                >
                    ×
                </a>
            </div>

            <button
                className="btn btn-primary editorial-search-form__submit-btn"
                disabled={!searchText}
                spellCheck={false}
                type="submit"
                title={buttonTitle}
            >

            </button>
        </form>
    );
};

export default EditorialSearchForm;
