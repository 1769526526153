import React from "react";
import ReactDom from "react-dom";
import EditorialComponent from "./editorial-component";


export default class SearchFormRenderer {
    constructor(container, data) {
        this._container = container;
        this._editorialData = data;
    }

    render() {
        ReactDom.render(<EditorialComponent {...this._editorialData} />, this._container);
    }

    destroy() {
        ReactDom.unmountComponentAtNode(this._container);
    }
};
