import React from "react";
import EditorialCarousel from "./EditorialCarousel";
import EditorialSearchForm from "./EditorialSearchForm";

require('@csn-theme/editors-rating');

function ArticleMedia(props) {
    var classes = ((props.classes || "") + " article-media").trim();

    return (
        <div className={classes}>
            {props.numPhotos > 0 && <span><i className="icon-camera"></i>{props.numPhotos}</span>}
            {props.numVideos > 0 && <span><i className="icon-video"></i>{props.numVideos}</span>}
        </div>
    );
}

function ArticleType(props) {
    var classes = props.type && props.type.toLowerCase().split(" ").join("-");

    return (<span className={classes}>{props.type}</span>);
}

function EditorsRating(props) {
    if (props.rating <= 0) return (<span></span>);
    return (<span className="editors-rating"><editors-rating value={props.rating} max-value={props.maxRating} show-max-value="false"></editors-rating></span>);
}

function Article(props) {

    if (props.type === "empty") return (<></>);

    var imgSrc = props.photo ? `${props.photo}?width=480&height=320&pxc_method=crop` : "";
    var imgClasses = "card-img-top";
    if (props.numVideos > 0) {
        imgClasses += " video-overlay";
        if (props.isBig) imgClasses += " big";
    }

    var classes = `card-slider-item ${props.isBig ? "big-card" : "small-card"}`;
    var titleClass = `card-title ${props.rating > 0 ? "narrow" : ""}`;

    var showFooterLink = props.footerLinkText && props.footerLinkUrl;

    var ownerReviewsRating =
        <div className="owner-review-rating">
            <div className="owner-review-rating-text">
                {Number(props.ownerReviewRating).toFixed(1)} / {props.maxRating}
            </div>
            <span className="owner-review-rating-star" />
        </div>

    var type = <div className="card-body-row fixed-line-ht">
        {<ArticleType {...props}></ArticleType>}
        {props.type !== "owner review" && <ArticleMedia {...props}></ArticleMedia>}
        {props.type !== "owner review" ? <EditorsRating {...props}></EditorsRating> : ownerReviewsRating}
    </div>

    var title = (<h5 className={titleClass} title={props.fullHeadline}>{props.headline}</h5>);

    var authorImage = (<img
        src={props.authorImageUrlPath}
        onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = props.defaultAuthorImageUrlPath;
        }}
    />);

    var footerAuthor = props.footerAuthorName?.length > 0 ? (<><span className="card-footer-author truncate">{props.footerAuthorName}</span><span>&nbsp;-&nbsp;</span></>) : "";
    var footerDate = (<span>{props.footerDate}</span>);

    if (props.footer !== '')
        var footer = (<div className="card-date">{props.footer}</div>);
    else
        var footer = (<div className="card-footer-text">{footerAuthor}{footerDate}</div>);

    var sponsoredByLabel = (<div className="card-sponsor-label">{props.brandPresentedByLabel}</div>);
    var footerLink = (<a href={props.footerLinkUrl} target="_blank">{props.footerLinkText}</a>);
    var body = props.isBig
        ? (<div className="card-body">
            {props.isSponsored &&
                <div className="card-body-row">
                    <span className="card-sponsored-label-big text-white">Sponsored</span>
                </div>}
            {title}
            <div className="card-body-row last-row ">
                {<ArticleType {...props}></ArticleType>}
                {props.brandSponsorName && sponsoredByLabel}
                {footer}
                {<ArticleMedia classes="right" {...props}></ArticleMedia>}
            </div>
        </div>)
        : (<div className="card-body">
            {type}
            {title}
            {<div className="card-body-row last-row fixed-line-ht">
                {!props.brandSponsorName && props.authorName && authorImage}
                {footer}
            </div>}
            {showFooterLink && footerLink}
        </div>);

    return (
        <div className={classes}>
            <a className="card text-body" href={props.detailsUrlPrefix + props.detailsUrl} target={props.openNewTab ? "_blank" : "_self"} data-webm-clickvalue={props.clickValue}>
                <div className={imgClasses}>
                    <img src={imgSrc} alt={`${props.fullHeadline} image`} />
                    {props.isSponsored && !props.isBig && <span className="card-sponsored-label text-white">Sponsored</span>}
                </div>
                {body}
            </a>
        </div>
    );
}

class Section extends React.Component {

    constructor(props) {
        super(props);
        this.state = { showModal: false };
    }

    mapArticles(articles) {
        var idCache = {};
        var isBig = this.props.cardsPerSlide <= 2;

        return articles.map(articleData => {
            var numDupes = idCache[articleData.id];
            idCache[articleData.id] = numDupes ? ++numDupes : 1;
            var clickValue = `${this.props.name}-tile-${this.props.articles.indexOf(articleData) + 1}`
            return (<Article key={`${articleData.id}-${numDupes}`} isBig={isBig} clickValue={clickValue} {...articleData}></Article>);
        });
    }

    render() {
        var showAll = this.props.showAllText && this.props.showAllUrl && this.props.showAllText.length && this.props.showAllUrl.length;
        var id = `editorial-${(this.props.title || "").toLowerCase().split(" ").join("-")}`;

        return (
            <div className="editorial-section" id={id}>
                <div className="editorial-header">
                    <h4>{this.props.title}</h4>
                    <div className="editorial-header-actions">
                        {
                            this.props.articleType === "owner review" &&
                            <>
                                <a className="text-primary submit-owner-review-action" href="#" onClick={(e) => { e.preventDefault(); this.setState({ showModal: true }) }} data-webm-clickvalue={`homepage-${this.props.name}-submit`}>
                                    {this.props.submitOwnerReviewText}
                                    <i className="icon-arrow-right"></i>
                                </a>
                                <Modal className="submit-owner-review-modal" show={this.state.showModal} closeModal={() => this.setState({ showModal: false })}>
                                    <iframe src={this.props.submitOwnerReviewUrl}></iframe>
                                </Modal>
                            </>
                        }
                        {showAll &&
                            <a className="text-primary" href={this.props.showAllUrl} target={this.props.openNewTab ? "_blank" : "_self"} data-webm-clickvalue={`homepage-${this.props.name}-all`}>
                                {this.props.showAllText}
                                <i className="icon-arrow-right"></i>
                            </a>}
                    </div>
                </div>
                <div className="card-slider">
                    <EditorialCarousel cardsPerSlide={this.props.cardsPerSlide}>
                        {this.mapArticles(this.props.articles)}
                    </EditorialCarousel>
                </div>
            </div>
        );
    }
}

const Modal = (props) => {
    if (!props.show) return null;
    return (
        <>
            <div className={'modal-overlay'} />
            <div className={props.className || "popup-modal"} >
                {props.children}
                <div className='modal-close' onClick={props.closeModal}></div>
            </div>
        </>
    );
}

export default class EditorialComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        var sections = this.props.sections
            .filter(s => s && s.articles && s.articles.length)
            .map(sectionData => (<Section key={sectionData.name} {...sectionData}></Section>));
        var showHeader = this.props.title;

        return (
            <div className="editorial" data-webm-section="editorial-homepage-module">
                {showHeader && <div className="editorial-header">
                    <h2>{this.props.title}</h2>
                    {this.props.search && <EditorialSearchForm {...this.props.search} />}
                    {this.props.editorialUrl && this.props.editorialText &&
                        <a className="btn btn-primary" href={this.props.editorialUrl} data-webm-clickvalue={this.props.editorialClickValue}>
                            {this.props.editorialText}
                        </a>
                    }
                </div>}
                {sections}
            </div>
        );
    }
}