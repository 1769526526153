import EditorialRenderer from "./Components/editorial-renderer";
require("./editorial.scss");
require("@babel/polyfill");

(function (editorialData) {
    if (!editorialData) return;
    const container = document.getElementById("editorial-container");
    const editorialRenderer = new EditorialRenderer(container, editorialData);
    editorialRenderer.render();
})(editorialData);
