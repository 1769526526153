import React from 'react';
import Slider from "react-slick";

const CarouselArrow = ({ className, style, onClick }) => {
    return (
        <button type="button" className={className + " carousel-button"} style={style} onClick={onClick} />
    )
}

const EditorialCarousel = ({cardsPerSlide, children}) => {
    const sliderSettings = {
        adaptiveHeight: false,
        arrows: true,
        dots: true,
        draggable: false,
        infinite: true,
        prevArrow: <CarouselArrow />,
        nextArrow: <CarouselArrow />,
        speed: 500,
        slidesToShow: cardsPerSlide,
        slidesToScroll: cardsPerSlide,
        responsive: [
            { breakpoint: 1200, settings: { slidesToShow: Math.min(3, cardsPerSlide) } },
        ]
    }

    return <Slider {...sliderSettings}>
        {children}
    </Slider>
}

export default EditorialCarousel;